<template>
  <div class="calendarioSection">
    <animated-component>
      <div class="header">
        <img src="../../../images/calendario.webp" />
        <div class="title">CALENDÁRIO</div>
      </div>
    </animated-component>

    <animated-component>
      <div class="tContainer">
        <div class="subtitle">
          Os encontros gerais serão online e à noite, mas o desenvolvimento 
          pode acontecer nos dias, horários e locais de preferência de cada 
          equipe. O resultado final de cada equipe será apresentado em 
          encontro presencial, dia 25 de Novembro.
        </div>
        <div class="tWrapper">
          <img src="../../../images/calendar.webp" />
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script setup></script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.calendarioSection {
  background-color: white;
  background-position: center;
  background-size: contain;
  padding: 100px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    min-width: 300px;

    img {
      height: 35px;
      width: 35px;
      margin-right: 10px;
    }

    .title {
      font-size: 30px;
      font-family: 'Montserrat', sans-serif;
      color: #555555;
      font-weight: 800;
    }
  }

  .tContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;

    max-width: 1080px;

    .subtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      color: #1e1e1e;
      font-weight: 400;
      font-size: 18px;
      max-width: 800px;
    }

    .tWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 100%;
        object-fit: contain;
        max-width: 800px;
      }
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 800px) {
  .calendarioSection {
    .tContainer {
      .subtitle {
        font-size: 14px;
      }
      .tWrapper {
        img {
          max-width: 120%;
        }
      }
    }
  }
}
</style>
