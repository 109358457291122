<template>
  <div class="aboutSection">
    <animated-component>
      <div class="about">
        <div class="header">
          <h1>
            Um hackathon global para trazer o evangelho para novas pessoas e
            novos lugares.
          </h1>
        </div>
        <div class="subtext">
          Participando do Hack, você faz a diferença e usa suas habilidades para
          promover o Reino de Deus por meio de projeto de inovação focado em
          missão. Pela primeira vez em João Pessoa, o Hack será realizado em
          formato híbrido no período de
          <span style="font-weight: bold">1 a 25 de novembro de 2022</span>.
          Aproveite a oportunidade para fazer amigos e resolver as necessidades
          missionárias da cidade.
        </div>

        <div class="subscribe">
          <Button @click="openUrl('https://www.eventbrite.com/e/hackathon-joao-pessoa-hack-2022-tickets-431301222567')">
            Inscreva-se</Button
          >
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script setup>
import Button from '/src/components/ui/Button.vue';
const openUrl = url => {
  window.open(url, '_blank');
};
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.aboutSection {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #000;

  .about {
    padding: 100px 40px;
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
    max-width: 800px;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;
      color: #555555;
      text-align: center;

      img {
        height: 35px;
      }

      h1 {
        font-size: 30px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
      }
    }

    .subtext {
      font-size: 18px;
      color: #1e1e1e;
      margin-bottom: 20px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
    }
    .subscribe {
      align-items: center;
      margin: auto;
      padding-top: 35px;
      width: 200px;
    }
  }
}

@media (max-width: 800px) {
  .aboutSection {
    .about {
      .header {
        h1 {
          font-size: 18px;
        }
      }
      .subtext {
        font-size: 14px;
      }
    }
  }
}
</style>
