<template>
  <div class="navBar" :class="navBarStyles">
    <div class="container">
      <div class="link" @click="openUrl('https://discord.gg/VJJtY4gPM2')">
        <DiscordIcon />
      </div>

      <div class="menuIcon" @click="showMobileMenu">
        <div class="line"></div>
        <div class="line"></div>
      </div>

      <MobileMenu
        :isVisible="mobileMenuIsVisible"
        :isHome="isHome"
        @toggleVisibility="mobileMenuIsVisible = false"
      />

    </div> <!-- container //-->
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';

import MobileMenu from './MobileMenu.vue';
import DiscordIcon from '../../components/icons/DiscordIcon.vue';

// Set up the default header component.
export default {
  name: 'Header',
  components: {
    MobileMenu,
    DiscordIcon
},
  props: {
    isHome: {
      type: Boolean
    }
  },
  data() {
    return {
      showDropdown: false,
      mobileMenuIsVisible: false
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
    }),

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    navBarStyles() {
      return this.isHome ? 'homeNavBar' : '';
    },

    addressStyles() {
      return this.isHome ? 'homeAddress' : '';
    }
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  onBeforeUnmount() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    ...mapActions({
      clearAlert: 'alert/clear'
    }),

    // Toggle the dropdown menu display.
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    // Close the dropdown menu.
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    // Route to a different path.
    routeTo(path) {
      this.$router.push(path);
    },

    openUrl(url) {
      window.open(url, '_blank');
    },

    showMobileMenu() {
      // Disable scrolling on window.
      window.onscroll = function() {
        window.scrollTo(0, 0);
      };
      this.mobileMenuIsVisible = true;
    }
  },

  // Clear the application alert when routes change.
  watch: {
    $route(to, from) {
      // this.clearAlert();
    }
  }
};
</script>

<style scoped lang="scss">
.navBar {
  background-color: black;
  padding: 20px;

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    .logo {
      margin-right: auto;
      margin-bottom: 0px;
      cursor: pointer;
      transition: all 300ms ease;

      &:active {
        transform: scale(0.95);
      }

      img {
        width: 150px;
      }
    }

    .link {
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 10px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      border: none;
      height: fit-content;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .line {
      border-bottom: 2px solid white;
      width: 100px;
    }

    .menuIcon {
      height: 70px;
      width: 70px;
      grid-template-columns: auto;
      grid-row-gap: 5px;
      color: white;
      padding: 20px;
      border-radius: 10px;
      display: none;

      .line {
        border-bottom: 2px solid white;
        width: 30px;
        height: 10px;
        box-sizing: border-box;
        &:last-child {
          margin-bottom: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .connect {
      box-shadow: inset 0 0 0 1px rgb(128, 128, 128);
    }

    .address {
      right: 20px;
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 10px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      background-color: transparent;
      border: none;
      width: fit-content;
      position: relative;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      .svg-icon {
        margin-left: 10px;
      }

      .profile-dropdown {
        position: absolute;
        top: 50px;
        right: 0px;
        width: 140px;
        padding: 10px;
        border-radius: 10px;
        background-color: black;
        font-size: 14px;
        z-index: 10;

        .dropdown-item {
          padding: 10px;
          padding-left: 15px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          transition: background-color 0.2s ease;

          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
          }

          .item-text {
            font-size: 16px;
            margin-left: 10px;
          }
        }
      }
    }

    .notConnected {
      cursor: pointer;
      padding: 15px 20px;
      border-radius: 10px;
      transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      background-color: #ffd915;
      color: black;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 3px;
      text-decoration: none;
      border: none;

      &:hover {
        background-color: #ffd915;
        transform: scale(1.03);
      }

      &:active {
        background-color: #ffd915;
        transform: scale(0.97);
      }
    }
  }
}

.homeNavBar {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
}

.wallet-modal-container {
  position: fixed;
  z-index: 9999;
}

.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;

  padding: 5px 30px;
  justify-items: center;
  position: relative;
  z-index: 99;
}

.header > img {
  height: 40px;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > img:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color);
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 160px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.profile_button:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.profile_button > p {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}

.profile-dropdown {
  position: absolute;
  top: 70px;
  right: 10px;
  width: 160px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 34px;
  background-color: black;
  font-size: 14px;
  z-index: 10;
}

.dropdown-item {
  color: var(--text-color);
  padding: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.dropdown-item > p {
  margin-left: 10px;
  margin-bottom: 3px;
}

.svg-icon {
  margin-top: -5px;
  margin-left: -5px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
  transition-delay: 0.5s;
}

.slide-enter-from
// .slide-leave-to
 {
  transform: translateY(-100px);
}

@media (max-width: 1050px) {
  .navBar .container {
  }
  .navBar .container .link {
    display: none;
  }

  .navBar .container .address {
    display: none;
  }

  .navBar .container .menuIcon {
    display: grid;
  }

  .navBar .container .logo {
    display: initial;
    width: fit-content;
    img {
      max-height: 49px;
    }
  }
}
</style>
