<template>
  <button
    class="button"
    :disabled="isDisabled"
    :class="secondaryStyles"
    :style="{ height }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    isSecondary: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isText: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      required: false
    }
  },
  computed: {
    secondaryStyles() {
      let classes = '';
      if (this.isSecondary) classes += 'secondary ';
      if (this.isDisabled) classes += 'disabled ';
      if (this.isText) classes += 'text';
      if (this.isActive) classes += 'is-active';
      return classes;
    }
  }
};
</script>

<style scoped>
.button {
  position: relative;
  z-index: 1;
  display: flex;
  height: 40px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: black;
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: white;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  -webkit-tap-highlight-color: transparent;
}

.button:hover {
  box-shadow: 0 10px 10px 0 rgba(255, 255, 255, 0.22),
    0 14px 28px 0 rgba(255, 255, 255, 0.25);
}

.button:active {
  box-shadow: none;
}

.secondary {
  border: 1px solid white;
  background-color: transparent;
  transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: white;
}

.secondary:hover,
.is-active.is-active {
  background-color: white;
  color: black;
}

.disabled {
  color: #999;
  background-color: lightgray;
  cursor: not-allowed;
}

.disabled:hover {
  box-shadow: none;
}

.text {
  background-color: black;
  border: none;
  color: white;
  box-shadow: none !important;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.text:hover {
  background-color: rgba(white, 0.1);
}

.text:active {
  background-color: rgba(white, 0.2);
}
</style>
