<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <defs></defs>
    <circle cx="15" cy="15" r="15" fill="#19C534"></circle>
    <path
      fill="#fff"
      d="M12.57 21.3l-4.63-4.63 2.11-2.11 2.52 2.53 7.38-7.38 2.1 2.1-9.48 9.5z"
    ></path></svg
></template>
