<template>
  <svg
    viewBox="0 0 71 55"
    width="20px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.1045 4.48606C55.5792 2.40966 50.7265 0.879855 45.6527 0.00367546C45.5603 -0.0132345 45.468 0.0290245 45.4204 0.113544C44.7963 1.22356 44.105 2.67166 43.6209 3.80986C38.1637 2.99286 32.7345 2.99286 27.3892 3.80986C26.905 2.64636 26.1886 1.22356 25.5617 0.113544C25.5141 0.0318445 25.4218 -0.0104145 25.3294 0.00367546C20.2584 0.877055 15.4057 2.40686 10.8776 4.48606C10.8384 4.50296 10.8048 4.53116 10.7825 4.56776C1.57795 18.3192 -0.943561 31.7326 0.293408 44.9797C0.299005 45.0445 0.335386 45.1065 0.385761 45.1459C6.45866 49.6057 12.3413 52.3132 18.1147 54.1078C18.2071 54.136 18.305 54.1022 18.3638 54.0261C19.7295 52.1611 20.9469 50.1946 21.9907 48.1266C22.0523 48.0055 21.9935 47.8618 21.8676 47.8139C19.9366 47.0814 18.0979 46.1883 16.3292 45.1741C16.1893 45.0924 16.1781 44.8923 16.3068 44.7965C16.679 44.5176 17.0513 44.2274 17.4067 43.9344C17.471 43.8809 17.5606 43.8696 17.6362 43.9034C29.2558 49.2085 41.8354 49.2085 53.3179 43.9034C53.3935 43.8668 53.4831 43.8781 53.5502 43.9316C53.9057 44.2246 54.2779 44.5176 54.6529 44.7965C54.7816 44.8923 54.7732 45.0924 54.6333 45.1741C52.8646 46.208 51.0259 47.0814 49.0921 47.8111C48.9662 47.859 48.9102 48.0055 48.9718 48.1266C50.038 50.1917 51.2554 52.1582 52.5959 54.0233C52.6519 54.1022 52.7526 54.136 52.845 54.1078C58.6464 52.3132 64.529 49.6057 70.6019 45.1459C70.6551 45.1065 70.6887 45.0473 70.6943 44.9825C72.1747 29.6674 68.2147 16.364 60.1968 4.57056C60.1772 4.53116 60.1437 4.50296 60.1045 4.48606ZM23.7259 36.9136C20.2276 36.9136 17.3451 33.7019 17.3451 29.7576C17.3451 25.8133 20.1717 22.6016 23.7259 22.6016C27.308 22.6016 30.1626 25.8415 30.1066 29.7576C30.1066 33.7019 27.28 36.9136 23.7259 36.9136ZM47.3178 36.9136C43.8196 36.9136 40.9371 33.7019 40.9371 29.7576C40.9371 25.8133 43.7636 22.6016 47.3178 22.6016C50.9 22.6016 53.7545 25.8415 53.6986 29.7576C53.6986 33.7019 50.9 36.9136 47.3178 36.9136Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
