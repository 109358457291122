<template>
  <SplashArea id="splash" />
  <AboutSection id="about" />
  <DesafiosSection id="desafios" />
  <CalendarioSection id="calendario" />
  <FAQSection id="faqs" />
  <VideoSection id="faqs" />
  <ApoioSection id="apoio" />
</template>
<script setup>
import SplashArea from './components/SplashArea.vue';
import AboutSection from './components/AboutSection.vue';
import FAQSection from './components/FAQSection.vue';
import DesafiosSection from './components/DesafiosSection.vue';
import CalendarioSection from './components/CalendarioSection.vue';
import VideoSection from './components/VideoSection.vue';
import ApoioSection from './components/ApoioSection.vue';


import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const isLoggedIn = computed(() => {
  return store.state.ethers.address;
});
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}
</style>
