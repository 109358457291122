<template>
  <div ref="target">
    <transition :name="animationType">
      <div v-appear="animate" class="animated-component">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="js">
import { onMounted, ref } from 'vue';
export default {
  name: 'AnimatedComponent',
  props: {
    animationType: {
      type: String,
      required: false,
      default: 'fade'
    }
  },
  setup(props) {
    const target = ref();
    const animate = ref(false);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!animate.value)
          animate.value = entry.isIntersecting;
      },
      {
        threshold: 0.5
      }
    );
    onMounted(() => {
      observer.observe(target.value);
    });
    return {
      animate,
      target
    };
  }
};
</script>

<style scoped>
.animated-component {
  height: 100%;
}
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from,
.animated-component.left-enter-from,
.animated-component.right-enter-from {
  transition: none;
}
/* Fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: all 500ms ease-out;
  transform: translateY(0px);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(80px);
}
/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
  transition: all 400ms ease-out;
  transform: translateY(0px);
}
.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
  transform: translateY(80px);
  transform: scale(0.5);
}

/* Zoom animation */
.left-enter-active,
.left-leave-active {
  transition: all 500ms ease-out;
  transform: translateX(0px);
}
.left-enter-from,
.left-leave-to {
  opacity: 0;
  transform: translateX(-80px);
}

/* Zoom animation */
.right-enter-active,
.right-leave-active {
  transition: all 500ms ease-out;
  transform: translateX(0px);
}
.right-enter-from,
.right-leave-to {
  opacity: 0;
  transform: translateX(80px);
}
</style>
