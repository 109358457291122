<template>
  <div class="footer">
    <div class="logo" href="#splash" v-smooth-scroll>
      <img
            src="../../images/HACK-logo-cursor.webp"
        alt="Footer Logo"
      />
    </div>

  </div>
</template>
<script setup>

</script>
<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .logo {
    width: 240px;
    max-width: 100%;
    margin-bottom: 0px;
    cursor: pointer;
    transition: all 500ms ease;

    &:active {
      transform: scale(0.9);
    }

    img {
      width: 200px;
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
    .logo {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .terms {
    justify-content: center;
  }
}
</style>
