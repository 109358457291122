<template>
  <div class="desafiosSection">
    <animated-component>
      <div class="header">
        <div class="title">O que é Hack?</div>
      </div>
    </animated-component>

    <animated-component>
      <div class="tWrapper">
        <YouTube
          src="https://www.youtube.com/watch?v=UnwH2B7inNg"
          ref="youtube"
        />
      </div>
    </animated-component>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';

const youtube = ref(null);

const onReady = () => {
  youtube.value.playVideo();
};

</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.desafiosSection {
  background-color: #262626;
  background-position: center;
  background-size: contain;
  padding: 100px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    min-width: 300px;

    img {
      height: 35px;
      width: 35px;
      margin-right: 10px;
    }

    .title {
      font-size: 30px;
      font-family: 'Montserrat', sans-serif;
      color: #fff;
      font-weight: 800;
    }
  }

  .tContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;

    max-width: 1080px;

    .subtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      color: #1e1e1e;
      font-weight: 600;
      font-size: 18px;
      max-width: 800px;
    }

    .tWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;

      iframe {
        width: 100%;
        max-width: 1020px;
      }
    }
  }
}
</style>
