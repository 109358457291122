'use strict';

// Imports.
import { createStore } from 'vuex';

// Import data modules for the store.
import alert from './alert';

// Export the data store using our modules.
export default createStore({
  state: {},
  mutations: {
  },
  actions: {},
  modules: {
    alert,
  }
});
